/* eslint-disable no-octal-escape */
import React from 'react'

import MemNavbar from '../components/memnavbar/MemNavbar'
import presidentgraphic from '../assets/presidentgraphic.png'
import { boardmems } from '../data'
import Boardmem from '../components/Boardmem'

const Members = ({ user }) => {
  return (
    <>
      <div className='mckw__membersnav-wrapper'>
        <MemNavbar />
      </div>
      <div className='mckw__members_header' />

      <div className='mckw__member_header-primary'>
        <h1>MWHOA Announcements</h1>
        <div id='board' className='mckw__member_header-secondary'>
          <h2>2025 Board Members</h2>

          {boardmems.map((boardmem) => (
            <Boardmem key={boardmem.id} boardmem={boardmem} />
          ))}
        </div>
      </div>
      <div className='mckw__member-presidents-wrapper'>
        <div className='mckw__member-presdients_container'>
          <div
            id='presidents'
            className='mckw__member-presidents-header_container'
          >
            <h1>President's Remark</h1>
            <img src={presidentgraphic} alt='talking bubbles grapic' />
          </div>

          <div className='mckw__member-presidents-copy'>
            <p>
              The Board of Directors of the Association intends to use this site
              for members to view important documents regarding our board and
              assoication business. Please take a moment to familiarize yourself
              with the site's contents for a summary of the news and activities
              to-date and any scheduled for the remainder of the year.
            </p>
            <p>
              The Board looks forward to keeping you informed as well as hearing
              from you about developments in the McKenna Woods community. Thank
              you for visiting the website and if you have any comments,
              questions or concerns, reach out to a board member to discuss.
              Thank you!
            </p>
          </div>
        </div>

        <div className='mckw__member-docs_container'>
          <div className='mckw__member-docs-list'>
            <h2>Upcoming Meetings</h2>
            <ul>
<li>May 13, 2025<br />
August 19, 2025</li>
            </ul>
          <h2>MWHOA Pinic</h2>
          <ul>To Be Announced</ul>
            <h2 id='rules'>Covenants, Rules & Regulations</h2>
            <ul>
              <li>
                <a href='docs\ARC_Submittal_Form_1-17-22.pdf' download>
                 Architectural Review Submittal Form
                </a>
              </li>
               <li>
                <a href='docs\Recorded-Covenants-8-20-19-sm.pdf' download>
                  Covenants and Bylaws
                </a>
              </li>
              <li>
                <a href='docs/Electronic-Communications-Rule.pdf' download>
                  Electronic Communications
                </a>
              </li>
              <li>
                <a href='docs\McKenna-Woods-Garage-Sale-Rules.pdf' download>
                  McKenna Woods Garage Sale Rules
                </a>
              </li>
              <li>
                <a href='docs\MWHOA-Rules-and-Regulations-3-12-19.pdf' download>
                  McKenna Woods Rules & Regulations
                </a>
              </li>
            </ul>
            <h2 id='budgets'>Annual Budgets</h2>
            <ul>
            <li>
                <a href='docs\MWHOA-2025-Annual-Budget.pdf' download>2025 Budget</a>
              </li>                
            </ul>
            <h2 id='meetings'>Annual Meeting Minutes</h2>
            <ul>
              <li><a href="docs\MWHOA-Annual-Meeting-Minutes-10-8-2024.pdf" download>October 8, 2024</a></li>
            </ul>


            <h2>2025 Board of Directors Meeting Minutes</h2>
            <ul><li><a href="docs\McKenna Woods HOA Minutes-Financials-1-28-2025.pdf" download>January 28, 2025</a></li>
            <li><a href="docs\McKenna-Woods-Special-Board-Minutes-Financials-11-19-2024.pdf" download>November 19, 2024</a></li>
            <li><a href="docs\McKenna-Woods-HOA-Minutes-Financials-10-29-2024.pdf" download>October 29, 2024</a></li>
            </ul>


            <h2>2024 Board of Directors Meeting Minutes</h2>
              <ul>
             
              <li>
                <a href="docs\McKenna-Woods-Minutes-Financials-9-10-2024.pdf" download> September 10, 2024</a>
              </li>
              <li>
                <a href="docs\McKenna-Woods-HOA-Minutes-Financials-6-18-24.pdf" download> June 18, 2024</a>
              </li>
              <li>
                <a href="docs\McKenna Woods HOA Minutes 3-11-2024.pdf" download> March 11, 2024</a>
              </li>
               <li>
                <a href="docs\McKenna Woods HOA Minutes 1-9-2024.pdf" download> January 9, 2024</a>
              </li>
             </ul>

          </div>
        </div>
      </div>
    </>
  )
}

export default Members
